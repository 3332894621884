import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDeviceType from '../utils/useDeviceType';
import servicesDesktopGifPath from '../assets/videos/updated_videos/services_desktop.mp4';
import servicesMobileGifPath from '../assets/videos/updated_videos/services_desktop.mp4';
import Layout from './Layout';
import axios from 'axios';
import { API_URL } from '../constant';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const serviceVideoRef = useRef(null);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const clickableAreaHeight = deviceType === 'mobile' ? '15%' : '18%';
  const clickableAreaWidth = deviceType === 'mobile' ? '23%' : '10%';

  const fallbackClickableAreas = [
    { name: 'Retail Branding', top: deviceType === 'mobile' ? '48%' : '47%', left: deviceType === 'mobile' ? '5%' : '29%' },
    { name: 'Laser & Cnc Cutting', top: deviceType === 'mobile' ? '32%' : '26%', left: deviceType === 'mobile' ? '2%' : '28%' },
    { name: '3D Acrylic Letter', top: deviceType === 'mobile' ? '16%' : '8%', left: deviceType === 'mobile' ? '10%' : '30%' },
    { name: 'Vehicle Branding', top: deviceType === 'mobile' ? '16%' : '8%', right: deviceType === 'mobile' ? '8%' : '30%' },
    { name: 'Franchise Store', top: deviceType === 'mobile' ? '32%' : '26%', right: deviceType === 'mobile' ? '2%' : '29%' },
    { name: 'Event & Exhibition', top: deviceType === 'mobile' ? '48%' : '46%', right: deviceType === 'mobile' ? '5%' : '29%' },
  ];

  const fetchAllServices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/get-category-data`, {
        params: { categoryName: categoryName },
      });
      setServices(response.data ?? []);
    } catch (error) {
      console.error('Failed to fetch services:', error);
    } finally {
      setLoading(false);
    }
  }, [API_URL, categoryName]);
  
  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  useEffect(() => {
    const video = serviceVideoRef.current;
    const playVideo = async () => {
      if (video) {
        try {
          await video.play();
          setIsVideoPlayed(true);
        } catch (error) {
          console.error('Error attempting to play the video:', error);
        }
      }
    };

    const timeoutId = setTimeout(playVideo, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleBranchClick = (categoryName) => {
    if (isVideoPlayed) {
      // navigate(`/services/${categoryName}`);
      navigate(`/service-details?category=${categoryName}`);
    }
  };

  return (
    <Layout>
      <div
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          height: deviceType === 'mobile' ? '70vh' : '80vh',
          margin: 0,
          padding: 0,
        }}
      >
        <video
          ref={serviceVideoRef}
          src={deviceType === 'mobile' ? servicesMobileGifPath : servicesDesktopGifPath}
          type='video/mp4'
          autoPlay
          muted
          controls={false}
          style={{
            width: deviceType === 'mobile' ? '175%' : '75.5%',
            objectFit: 'cover',
          }}
        />

        {services.length > 0 ? 
          services?.map(({ categoryName, top, left, right }, index) => (
            <div
              key={index}
              onClick={() => handleBranchClick(categoryName)}
              style={{
                position: 'absolute',
                top: top || fallbackClickableAreas[index]?.top,
                left: left || fallbackClickableAreas[index]?.left,
                right: right || fallbackClickableAreas[index]?.right,
                width: deviceType === 'mobile' ? '11%' : '14%',
                height: deviceType === 'mobile' ? '6%' : '15%',
                cursor: 'pointer',
                pointerEvents: 'auto',
                zIndex: 10,
              }}
            ></div>
          ))
        : fallbackClickableAreas?.map(({ name, top, left, right }, index) => (
            <div
              key={index}
              onClick={() => handleBranchClick(name)}
              style={{
                position: 'absolute',
                top,
                left,
                right,
                width: clickableAreaWidth,
                height: clickableAreaHeight,
                borderRadius: '50%',
                cursor: 'pointer',
                pointerEvents: 'auto',
                zIndex: 10
              }}
            ></div>
          ))
        }
      </div>
    </Layout>
  );
};

export default Services;
