import React, { useEffect, useRef, useState } from 'react';
import Layout from './Layout';
import useDeviceType from '../utils/useDeviceType';
import OurNetworkGifPath from '../assets/videos/updated_videos/our_network_desktop.mp4';
import OurNetworkMobileGifPath from '../assets/videos/updated_videos/our_network_mobile.mp4';
import Network from '../components/Network';

const OurNetwork = () => {
  const deviceType = useDeviceType();
  const ourNetworkVideoRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState(''); 
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const clickableAreaHeight = deviceType === 'mobile' ? '4%' : '5.5%';
  const clickableAreaWidth = deviceType === 'mobile' ? '5%' : '2%';

  const handleVideoPlay = () => {
    sessionStorage.setItem('ourNetworkVideoRef', 'true');
    setIsVideoPlayed(true);
  };

  useEffect(() => {
    const video = ourNetworkVideoRef.current;
    const hasPlayed = sessionStorage.getItem('ourNetworkVideoRef');

    if (hasPlayed && video) {
      setIsVideoPlayed(true);
      video.addEventListener('loadedmetadata', () => {
        if (video.duration) {
          video.currentTime = video.duration;
          video.pause();
        }
      });
    }

    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', () => {});
      }
    };
  }, []);
  
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('ourNetworkVideoRef');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const clickableAreaStyle = {
    position: 'absolute',
    width: clickableAreaWidth,
    height: clickableAreaHeight,
    cursor: isVideoPlayed ? 'pointer' : 'not-allowed',
    pointerEvents: isVideoPlayed ? 'auto' : 'none'
  };

  const handleClick = (name, title) => {
    setName(name);
    setTitle(title);
    setModalOpen(true);
  };

  return (
    <Layout>
      <div
        style={
          deviceType === 'mobile'
            ? {
                backgroundColor: 'transparent',
                position: 'relative',
                height: '100%',
                width: '100%',
                padding: '10px',
              }
            : {
                backgroundColor: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                height: '100%',
                width: '100%',
              }
        }
      >
        <video
          ref={ourNetworkVideoRef}
          src={deviceType === 'mobile' ? OurNetworkMobileGifPath : OurNetworkGifPath}
          type='video/mp4'
          autoPlay
          muted
          onPlay={handleVideoPlay}
          controls={false}
          style={
            deviceType === 'mobile'
              ? {
                  width: '100%',
                }
              : {
                  width: '71%',
                }
          }
        ></video>

        <div
          style={{ ...clickableAreaStyle, top: deviceType === 'mobile' ? '37.5%' : '35%', left: deviceType === 'mobile' ? '47%' : '50.5%' }}
          onClick={() => handleClick('D/305, Chandra Nagar, Bhagwanpur, Near Gardaniya School, Lanka Varanasi, Uttar Pradesh 221 005', 'Varanasi Branch Office')}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === 'mobile' ? '43%' : '45.5%', left: deviceType === 'mobile' ? '17%' : '39%' }}
          onClick={() => handleClick('Barcelona Industrial Park, SP Ring Road,Odhav, Opp., Odhav Police Station, Ahmedabad - 382415, Gujrat, India.', 'Ahmedabad Branch Office')}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === 'mobile' ? '46.5%' : '50%', left: deviceType === 'mobile' ? '37.5%' : '46.8%' }}
          onClick={() => handleClick('Sharda Bhavan, Dhondha Chowk, Behind Seva Sadan, C.A. Road, Nagpur 440 012', 'Nagpur Branch Office')}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === 'mobile' ? '51%' : '58%', left: deviceType === 'mobile' ? '17.5%' : '39%' }}
          onClick={() => handleClick('306 Shivam Chamber, Near Ram Mandir Signal, S.V. Road, Goregoan West, Mumbai 400 104, Maharashtra, India', 'Registered Office')}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === 'mobile' ? '51%' : '58%', left: deviceType === 'mobile' ? '22.5%' : '41%' }}
          onClick={() => handleClick('Sai Apartment, 306/307, Darshan Park Society Rd, Shambhu Vihar Society Aundh, Pune - 411007, Maharashtra, India', 'Pune Branch Office')}
        ></div>
        
        <Network isOpen={isModalOpen} onClose={() => setModalOpen(false)} title={title} name={name} /> 
      </div>
    </Layout>
  );
};

export default OurNetwork;
