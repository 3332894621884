import React from 'react';
import Layout from './Layout';
import aboutImage from '../assets/images/about-us.jpg';
import ISOImage from '../assets/images/ISO Certificate logo.jpg';
import clients from '../assets/images/desktop-clients (1).png';
import clientsMobile from '../assets/images/mobile-clients.png';
import useDeviceType from '../utils/useDeviceType';
import Breadcrumb from '../components/Breadcrumb';

const AboutUs = () => {
  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  const sectionPadding = isMobile ? '20px' : '50px';
  const contentPadding = isMobile ? '15px 10px' : '20px';
  const textStyle = { marginBottom: isMobile ? '5px' : '0px' };

  return (
    <Layout>
      <Breadcrumb path={['Home', 'About Us']} />

      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
        }}
      >
        <section
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            marginBottom: '50px',
            padding: sectionPadding,
            gap: '30px',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '50%', padding: isMobile ? '0px' : '20px' }}>
            <img
              src={aboutImage}
              alt="About Us"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                border: '1px solid #fad6cf',
                borderRadius: '10px',
              }}
            />
          </div>

          <div
            style={{
              padding: contentPadding,
              backgroundColor: '#fcf8f7',
              borderRadius: '10px',
              border: '1px solid #f0f0f0',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
              width: isMobile ? '100%' : '50%',
              minHeight: isMobile ? 'auto' : '600px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              justifyContent: 'space-between',
            }}
          >
            <h3 style={textStyle}>Architects of impactful advertising solutions</h3>
            <p style={{ ...textStyle, paddingLeft: '10px' }}>
              Based in the dynamic city of Mumbai, Maharashtra, Elora Art has proudly led the retail advertisement solutions industry for over two decades. With a steadfast commitment to innovation and customer satisfaction, we specialize in delivering exceptional Above-The-Line (ATL) and Below-The-Line (BTL) advertising solutions across India.
            </p>

            <h3 style={textStyle}>Our Mission</h3>
            <p style={{ ...textStyle, paddingLeft: '10px' }}>
              At Elora Art, we strive relentlessly to establish ourselves as the world’s most respected provider of marketing promotion services. Each day, we push the boundaries of creativity and excellence to exceed the expectations of our clients and industry peers alike.
            </p>

            <h3 style={textStyle}>Our Vision</h3>
            <p style={{ ...textStyle, paddingLeft: '10px' }}>
              To become the most passionately referred agency in the Advertising industry, setting new benchmarks through our unwavering dedication to quality, reliability, and client success.
            </p>

            <h3 style={textStyle}>What Sets Us Apart</h3>
            <ul style={{ paddingLeft: '30px', listStyleType: 'disc' }}>
              <li style={textStyle}>Creative Excellence: Our team is renowned for transforming innovative ideas into impactful campaigns that resonate with audiences.</li>
              <li style={textStyle}>Cutting-Edge Technology: Leveraging state-of-the-art large format digital printers to ensure precision and quality in every project.</li>
              <li style={textStyle}>Strong Market Presence: With a robust network and expertise, we deliver insightful solutions at competitive rates, tailored to meet diverse client needs.</li>
            </ul>

            <h3 style={textStyle}>Why Choose Elora Art?</h3>
            <ul style={{ paddingLeft: '30px', listStyleType: 'disc' }}>
              <li style={textStyle}>20 Years of Excellence: Trusted industry experience since 2004.</li>
              <li style={textStyle}>Creative Expertise: Innovative ideas with flawless execution.</li>
              <li style={textStyle}>Nationwide Reach: Strong network ensuring pan India coverage.</li>
              <li style={textStyle}>State-of-the-Art Technology: Utilizing cutting-edge large format digital printers.</li>
            </ul>
          </div>
        </section>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img
            src={ISOImage}
            alt="About Us"
            style={{
              width: isMobile ? '90%' : '50%',
              height: 'auto',
              objectFit: 'cover',
              border: '1px solid #fad6cf',
              borderRadius: '10px',
            }}
          />
        </div>

        <section style={{ marginTop: isMobile ? '60px' : '100px' }}>
          <h2 style={{ textAlign: 'center' }}>Our Valuable Clients</h2>
          <div style={{ padding: sectionPadding }}>
            <img
              src={isMobile ? clientsMobile : clients}
              alt='Our Clients'
              style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', display: 'block', borderRadius: '10px' }}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;
