import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL, APP_URL } from '../constant';
import { RxArrowTopRight } from 'react-icons/rx';
import Layout from './Layout';
import Modal from '../components/Modal';
import useDeviceType from '../utils/useDeviceType';
import images_not_found from '../assets/images/coming-soon.jpg';
import video_one from '../assets/videos/WEBSITE VIDEO_01.mp4';
import video_two from '../assets/videos/new_infra.mp4';

const ServiceDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryName = queryParams.get('category');
  const deviceType = useDeviceType();
  const pathname = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [categoryTitle, setCategoryTitle] = useState('');
  const [services, setServices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchAllServices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/get-category-data`, {
        params: { categoryName: categoryName },
      });
      const filteredServices = response.data?.data?.filter((service) => !service?.name?.toLowerCase().includes(categoryName?.toLowerCase()));
      setServices(filteredServices);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [categoryName]);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  const fetchAllProducts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_URL}/get-product-data`, { category_name: categoryName });
      setCategoryTitle(response.data?.category);

      const photos = response.data?.data?.map(product => `${APP_URL}/storage/${product?.photo}`);
      setPhotos(photos);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [categoryName]);

  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentIndex(0);
  };

  return (
    <Layout>
      <div
        style={{
          padding: deviceType === 'mobile' ? '30px' : '30px 0px',
          minHeight: '70vh'
        }}
      >
        <h1 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '20px' }}>{categoryTitle}</h1>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {loading ? (
            <h2 style={{ margin: '200px' }}>loading...</h2>
          ) : (categoryName?.trim().toLowerCase() === 'laser') ? (
            <div style={{
              display: 'flex',
              flexDirection: deviceType === 'mobile' ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              maxWidth: '90%',
              overflow: 'hidden',
              flexWrap: 'wrap',
            }}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: deviceType === 'mobile' ? '100%' : '48%',
                marginBottom: '20px',
              }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '20px' }}>Laser Cutting</h2>
                <video
                  src={video_one}
                  type="video/mp4"
                  muted
                  controls
                  style={{
                    border: '1px solid',
                    borderColor: '#fce4de',
                    borderRadius: '12px',
                    height: 'auto',
                    maxHeight: deviceType === 'mobile' ? '' : '550px',
                    aspectRatio: deviceType === 'mobile' ? '' : '16 / 9',
                    boxSizing: 'border-box',
                    width: '100%',
                  }}
                />
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: deviceType === 'mobile' ? '100%' : '48%',
                marginBottom: '20px',
              }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '20px' }}>CNC Router Cutting</h2>
                <video
                  src={video_two}
                  type="video/mp4"
                  muted
                  controls
                  style={{
                    border: '1px solid',
                    borderColor: '#fce4de',
                    borderRadius: '12px',
                    height: 'auto',
                    maxHeight: deviceType === 'mobile' ? '' : '550px',
                    aspectRatio: deviceType === 'mobile' ? '' : '16 / 9',
                    boxSizing: 'border-box',
                    width: '100%',
                  }}
                />
              </div>
            </div>
          ) : (error || photos?.length === 0) ? (
            <img
              src={images_not_found}
              alt="Coming Soon"
              style={{
                width: '30%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <div style={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: deviceType === 'mobile' ? '1fr' : 'repeat(3, 1fr)'
            }}>
              {photos?.map((photo, index) => (
                <div
                  key={index}
                  style={{
                    height: '300px',
                    boxSizing: 'border-box',
                    padding: deviceType === 'mobile' ? '20px' : '15px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    transition: 'transform 0.3s',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                  onClick={() => openModal(index)}
                >
                  <img
                    src={photo}
                    alt={`Service ${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'filter 0.3s',
                      borderRadius: '8px',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.filter = 'brightness(0.8)'}
                    onMouseOut={(e) => e.currentTarget.style.filter = 'none'}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <p style={{ marginTop: '60px', fontSize: '20px', fontWeight: '800', textAlign: 'center' }}>Similar Services</p>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '20px',
        }}>
          {services && services?.map((service, index) => (
            <button
              key={index}
              className="services-button"
              onClick={() => navigate(`/service-details?category=${service?.name}`)}
            >
              <span>{service?.name}</span> <RxArrowTopRight />
            </button>
          ))}
        </div>

        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          photos={photos}
          currentIndex={currentIndex}
        />
      </div>
    </Layout>
  );
};

export default ServiceDetails;
