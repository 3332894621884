import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import homeGifPath from '../assets/videos/updated_videos/home_desktop.mp4';
import homeMobileGifPath from '../assets/videos/updated_videos/home_mobile.mp4';
import useDeviceType from '../utils/useDeviceType';

const Home = () => {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const videoRef = useRef(null);
  const clickableAreaHeight = deviceType === 'mobile' ? '13%' : '20%';
  const clickableAreaWidth = deviceType === 'mobile' ? '23%' : '10%';
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const handleBranchClick = (branch) => {
    if (isVideoPlayed) {
      navigate(branch);
    }
  };

  const handleVideoCanPlay = () => {
    sessionStorage.setItem('videoRef', 'true');
    setIsVideoPlayed(true);
  };

  useEffect(() => {
    const hasPlayed = sessionStorage.getItem('videoRef');
    const video = videoRef.current;

    if (hasPlayed && video) {
      setIsVideoPlayed(true);
      video.addEventListener('loadedmetadata', () => {
        if (video.duration) {
          video.currentTime = video.duration;
          video.pause();
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('videoRef');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        marginTop: '20px',
      }}
    >
      <video
        ref={videoRef}
        src={deviceType === 'mobile' ? homeMobileGifPath : homeGifPath}
        type='video/mp4'
        autoPlay
        muted
        onCanPlay={handleVideoCanPlay}
        controls={false}
        style={deviceType === 'mobile' ? { width: '100%' } : { width: '80%' }}
      />

      {[
        { top: deviceType === 'mobile' ? '45%' : '46%', left: deviceType === 'mobile' ? '10%' : '28%', branch: 'about-us' },
        { top: deviceType === 'mobile' ? '27%' : '24%', left: deviceType === 'mobile' ? '3%' : '26%', branch: 'our-team' },
        { top: deviceType === 'mobile' ? '11%' : '3.5%', left: deviceType === 'mobile' ? '12%' : '30%', branch: 'services' },
        { top: deviceType === 'mobile' ? '8%' : '1%', right: deviceType === 'mobile' ? '38%' : '45%', branch: 'our-network' },
        { top: deviceType === 'mobile' ? '12%' : '3.5%', right: deviceType === 'mobile' ? '11%' : '30%', branch: 'our-infrastructure' },
        { top: deviceType === 'mobile' ? '27%' : '23.5%', right: deviceType === 'mobile' ? '5%' : '27%', branch: 'contact-us' },
        { top: deviceType === 'mobile' ? '45%' : '45%', right: deviceType === 'mobile' ? '15%' : '28%' }
      ]?.map((area, index) => (
        <div
          key={index}
          onClick={() => handleBranchClick(area.branch)}
          style={{
            position: 'absolute',
            top: area.top,
            left: area.left,
            right: area.right,
            borderRadius: '50%',
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: isVideoPlayed ? 'pointer' : 'not-allowed',
            pointerEvents: isVideoPlayed ? 'auto' : 'none'
          }}
        ></div>
      ))}
    </div>
  );
};

export default Home;
