import React from 'react';
import Card from '../components/Card';
import Layout from './Layout';
import useDeviceType from '../utils/useDeviceType';
import { infrastructureData as Infrastructure } from '../json/Infrastructure';
import Breadcrumb from '../components/Breadcrumb';
import video_three from '../assets/videos/WEBSITE VIDEO_03.mp4';

const OurInfraStructure = () => {
  const deviceType = useDeviceType();

  const containerStyle = {
    height: 'auto',
    width: '100%',
    color: 'black',
    padding: deviceType === 'mobile' ? '10px' : '30px',
  };

  return (
    <Layout>
      <Breadcrumb path={['Home', 'Our Infrastructure']} />
      <div style={containerStyle}>
        <div
          style={{
            padding: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: deviceType === 'mobile' ? '100%' : '55%',
            margin: 'auto',
            overflow: 'hidden'
          }}
        >
          <video
            src={video_three}
            type="video/mp4"
            autoPlay
            muted
            loop
            controls={false}
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
            }}
          />
        </div>
        <section 
          style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: '40px',
            marginTop: '50px',
          }}
        >
          {Infrastructure?.map((infra) => (
            <Card 
              key={infra?.id || infra?.name}
              image={infra?.image} 
              name={infra?.name} 
              description={infra?.description} 
            />
          ))}
        </section>
      </div>
    </Layout>
  );
};

export default OurInfraStructure;
